:root {
    $blue: #112960;
    --custom-property: #fff;
}

*, *::before, *::after {
    box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');

body {
    font-size: 16px;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    background: #fff;
    color: $blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
}


img {
    display: block;
    transition: all  .2s ease-in-out;
    width: 600px;
    height: auto;
    max-width: 90%;
    margin: 0 auto 30px;
    
    &:hover {
        transform: scale(1.2);
    }
}

ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;

    li {
        font-weight: 700;
        margin: 5px;
        animation: fadeIn 1s;
        opacity: 0;
        animation-fill-mode: forwards;

        &:nth-child(2) {
            animation-delay: .5s;
        }
        
        &:nth-child(3) {
            animation-delay: 1s;
        }
    }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}